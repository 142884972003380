
import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
@Component({
    components: { BackButton },
})
export default class AboutUsIndex extends Vue {
    supportEmailLink: string = ("mailto:" +
        process.env.VUE_APP_APP_SUPPORT_EMAIL) as string;
    twitterLink: string = process.env.VUE_APP_TWITTER_PAGE_URL as string;
    facebookLink: string = process.env.VUE_APP_FACEBOOK_PAGE_URL as string;
}
